/**
 * This file contains the container component for the ItemDetail component.
 * It manages the state and behavior of the ItemDetail, including rendering
 * 1. Next and Previous buttons for navigating agenda items
 * 2. Tabs for displaying the agenda item and speakers
 */

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'antd';
import { Button, CardHeader } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectItemKeys,
  selectSelectedItem,
  selectSelectedItemKey,
  setSelectedItemKey,
} from '../activeAgendaSlice';
import { setActiveTab, setCalledSpeaker, upsertSpeaker, updateSpeaker, deleteSpeaker } from '../../../common/liveMeetingSpeakerSlice';
import { displayErrorNotification } from '../../../common/liveMeetingSlice';
import AgendaItemDetail from './AgendaItemDetail';
import Speakers from './Speakers';
import { selectIsCloudVotingEnabled } from "../../../common/settingSlice";
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import { getJWTToken } from '../../../../helpers/votecastWebHelpers/graphqlOperations';
import { VotecastRequestOrigin, SpeakerStatus, VotecastAudience, ErrorNotificationMessage } from '../../../../constants/cloudVotingConstants';
import { onVotecastCreateDeleteSpeaker } from '../../../../../amplify/graphql/subscriptions';

export default function Container() {
  const dispatch = useDispatch();

  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemKeys = useSelector(selectItemKeys);
  const activeTab = useSelector(state => state.liveMeetingSpeaker.activeTab)
  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);

  const item = useSelector((state) =>
    selectSelectedItem(state, selectedItemKey)
  );

  const customerId = useSelector(state => state.auth.customer_uid);
  const memberDetails = useSelector(state => state.liveMeeting.memberDetails);
  const memberJoinStatus = memberDetails?.memberJoinStatus;
  const votecastAgendaUid = useSelector(state => state.liveMeeting.votecastAgendaUid);
  const userName = useSelector(state => state.auth.username);
  const speakers = useSelector((state) => state.liveMeetingSpeaker.speakers);
  const calledSpeaker = useSelector(state => state.liveMeetingSpeaker.calledSpeaker);
  const speakersListRef = useRef(speakers);
  const calledSpeakerRef = useRef(calledSpeaker);

  useEffect(() => {
    if (isCloudVotingEnabled) {
      speakersListRef.current = speakers;
      calledSpeakerRef.current = calledSpeaker;
    }
  }, [speakers, calledSpeaker, isCloudVotingEnabled])

  const handleSubscriptionError = (error) => {
    const errorMessage = error?.error?.errors?.[0]?.message?.toLowerCase() || '';

    if (errorMessage) {
      if (errorMessage.includes('connection closed') || errorMessage.includes('connection timeout')) {
        dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_TIMEOUT_NOTIFICATION));
      } else {
        dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_ERROR_NOTIFICATION ));
      }
    } else {
      console.error('Unknown error structure:', error);
      dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_ERROR_NOTIFICATION));
    }
  };

  //#region - #GUB-67045 - Cloudvoting change: speaker subscription for legislate in Dynamo DB
  useEffect(() => {
    let speaker_subscription;
    const subscribeToSpeaker = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        speaker_subscription = GraphQLAPI.graphql(graphqlOperation(onVotecastCreateDeleteSpeaker,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: (response) => {
            const subscribedData = response?.value?.data?.onVotecastCreateDeleteSpeaker;

            if (!subscribedData) return;
            switch (subscribedData.request_origin) {
              case VotecastRequestOrigin.ATTENDEE_CREATE:
                if (subscribedData.name !== userName) {
                  const requestedSpeaker = {
                    customer_uid: subscribedData.customer_uid,
                    agenda_uid: subscribedData.agenda_uid,
                    uid: subscribedData.uid,
                    name: subscribedData.name,
                    speaker_type: subscribedData.speaker_type,
                    duration: subscribedData.duration,
                    position: subscribedData.position,
                    item_uid: subscribedData.item_uid,
                    specialPosition: subscribedData.specialPosition,
                    start_time: subscribedData.start_time,
                    status: subscribedData.status,
                    request_origin: subscribedData.request_origin,
                    created_at: subscribedData.created_at,
                  };

                  dispatch(upsertSpeaker(requestedSpeaker));
                }
                break;

              case VotecastRequestOrigin.CLERK_CREATE:
                const addedSpeaker = {
                  customer_uid: subscribedData.customer_uid,
                  agenda_uid: subscribedData.agenda_uid,
                  uid: subscribedData.uid,
                  name: subscribedData.name,
                  speaker_type: subscribedData.speaker_type,
                  duration: subscribedData.duration,
                  position: subscribedData.position,
                  item_uid: subscribedData.item_uid,
                  specialPosition: subscribedData.specialPosition,
                  start_time: subscribedData.start_time,
                  status: subscribedData.status,
                  request_origin: subscribedData.request_origin,
                  created_at: subscribedData.created_at,
                };
                dispatch(upsertSpeaker(addedSpeaker));
                break;

              case VotecastRequestOrigin.CLERK_DELETE:
              case VotecastRequestOrigin.ATTENDEE_DELETE:
                dispatch(deleteSpeaker(subscribedData.uid));
                break;

              case VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE:
              case VotecastRequestOrigin.CLERK_UPDATE:
                if ([SpeakerStatus.CALLED, SpeakerStatus.STARTED, SpeakerStatus.STOPPED].includes(subscribedData.status)) {
                  subscribedData.duration = subscribedData.duration === -1 ? 0 : subscribedData.duration;
                  dispatch(setCalledSpeaker(subscribedData));
                } else {
                  dispatch(updateSpeaker(subscribedData));
                }
                break;

              default:
                break;
            }
          },
          error: (error) => {
            console.error('Subscription speaker_subscription error:', error);
            handleSubscriptionError(error);
          }
        });

      } catch (error) {
        console.error('Error initializing speaker_subscription:', error);
        handleSubscriptionError(error);
      }
    };

    if (isCloudVotingEnabled && votecastAgendaUid && customerId && memberJoinStatus) {
      subscribeToSpeaker();
    }
    return () => {
      if (speaker_subscription) {
        speaker_subscription.unsubscribe();
      }
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus]);
  //#endregion - #GUB-67045 subscription for legislate

  const isFirstItem = () =>
    !item || itemKeys.findIndex((i) => i === selectedItemKey) === 0;
  const isLastItem = () =>
    !item ||
    itemKeys.findIndex((i) => i === selectedItemKey) === itemKeys.length - 1;

  const handleNextClick = () => {
    if (isLastItem()) {
      return;
    }
    const nextIndex = itemKeys.findIndex((i) => i === selectedItemKey) + 1;
    dispatch(setSelectedItemKey(itemKeys[nextIndex]));
  };

  const handlePrevClick = () => {
    if (isFirstItem()) {
      return;
    }
    const prevIndex = itemKeys.findIndex((i) => i === selectedItemKey) - 1;
    dispatch(setSelectedItemKey(itemKeys[prevIndex]));
  };

  const prevButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handlePrevClick}
      disabled={isFirstItem()}
    >
      <FontAwesomeIcon
        icon={'angle-left'}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
      <span>Previous Item</span>
    </Button>
  );

  const nextButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handleNextClick}
      disabled={isLastItem()}
    >
      <span>Next Item</span>
      <FontAwesomeIcon
        icon={'angle-right'}
        size={'lg'}
        style={{ marginLeft: 4 }}
      />
    </Button>
  );

  const handleTabChange = (key) => {
    dispatch(setActiveTab(key));
  };

  return (
    <div className="agenda-item-details-container">
      <CardHeader className="item-detail-header display-flex">
        <div className="flex-auto">{prevButton}</div>
        <div className="flex-auto">{nextButton}</div>
      </CardHeader>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleTabChange}
        className="item-details-content"
        items={[
          {
            label: 'Item Details',
            key: '1',
            children: <AgendaItemDetail />,
          },
          isCloudVotingEnabled && {
            label: 'Speakers',
            key: '2',
            children: <Speakers />,
          },
        ].filter(Boolean)}
      />
    </div>
  );
}
