import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { displayErrorNotification } from '../../common/liveMeetingSlice';

const ErrorNotification = () => {

  const dispatch = useDispatch();
  const closeErrorNotification = () => {
    dispatch(displayErrorNotification(null));
    //To reload the page after closing the errror notification
    window.location.reload();
  }

  const errorMessage = useSelector(state => state.liveMeeting.errorMessage);

  return (
    <div id="error-container">
      <div id="error-info-panel">
        <div className="error-notification-header">
          <FontAwesomeIcon icon={['fas', 'info-circle']} size="lg" />
          <p>{errorMessage}</p>
          <button id="close-error" aria-label="Close" className="close-button" onClick={closeErrorNotification}>
            <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotification;