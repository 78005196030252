import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CalledSpeaker from '../../LiveMeeting/Speakers/CalledSpeaker';
import { Button } from '@trussworks/react-uswds';
import DeleteConfirmation from '../../Common/DeleteConfirmation';
import { VotecastRequestOrigin, SpeakerStatus, VotecastAudience } from '../../../../constants/cloudVotingConstants';
import { updateVotecastSpeaker, deleteVotecastSpeaker } from '../../../../../amplify/graphql/mutations';
import { invokeGraphqlOperation } from '../../../../helpers/votecastWebHelpers/graphqlOperations';
import { setCalledSpeaker, deleteSpeaker } from '../../../common/liveMeetingSpeakerSlice';
import { speakerCustomSort } from '../../../../helpers/votecastWebHelpers/speakerCustomSort';

export default function Speakers() {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const speakersList = useSelector((state) => state.liveMeetingSpeaker.speakers);
    const calledSpeaker = useSelector((state) => state.liveMeetingSpeaker.calledSpeaker);
    const calledSpeakerUid = calledSpeaker ? calledSpeaker.uid : '';
    const isChair = useSelector(state => state.liveMeeting.memberDetails.isChair);
    const selectedItemKey = useSelector(state => state.activeAgenda.selectedItemKey);
    const itemsDictionary = useSelector(state => state.liveMeeting.agendaItemsDictionary);
    const selectedItemHypatia = itemsDictionary[selectedItemKey];
    const customerId = useSelector(state => state.auth.customer_uid);
    const votecastAgendaUid = useSelector(state => state.liveMeeting.votecastAgendaUid);
    const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

    const getSpeakersForSelectedItem = () => {
        const speakers = speakersList.filter(speaker => speaker.item_uid === selectedItemHypatia?.uid);
        return speakerCustomSort(speakers);
    };

    const isSpeaking = !!calledSpeaker?.name && selectedItemHypatia?.uid === calledSpeaker?.item_uid;

    const attendeeDeleteSpeaker = async (speaker) => {
        await invokeGraphqlOperation(deleteVotecastSpeaker,
            { item_uid: speaker.item_uid, uid: speaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE }, authParameter);
        setIsDeleteConfirmationOpen(false);
        dispatch(deleteSpeaker(speaker.uid));
    };

    const handleClearCurrentSpeaker = async () => {
        await invokeGraphqlOperation(deleteVotecastSpeaker,
            { item_uid: calledSpeaker.item_uid, uid: calledSpeaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE }, authParameter);
        dispatch(deleteSpeaker(calledSpeaker.uid));
        dispatch(setCalledSpeaker(undefined));
    };

    const callSpeaker = async (speaker) => {
        if (calledSpeaker && calledSpeaker.uid) {
            await invokeGraphqlOperation(deleteVotecastSpeaker,
                { item_uid: calledSpeaker.item_uid, uid: calledSpeaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE }, authParameter);
        }
        const { created_at, ...speakerWithoutCreatedAt } = speaker;         // Removed created_at field, since updateVotecastSpeaker doesn't need it
        await invokeGraphqlOperation(updateVotecastSpeaker, {
            input: {
                ...speakerWithoutCreatedAt,
                customer_uid: customerId,
                agenda_uid: votecastAgendaUid,
                status: SpeakerStatus.CALLED,
                request_origin: VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE
            }
        }, authParameter);
        dispatch(setCalledSpeaker({
            ...speaker,
            duration: speaker.duration === -1 ? 0 : speaker.duration,
            status: SpeakerStatus.CALLED,
            request_origin: VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE
        }));
    }

    return (
        <div>
            <hr className="horizontal-line" />
            {isChair && (
                <div className="clear-current-speaker-button">
                    <Button
                        type="button"
                        unstyled
                        onClick={handleClearCurrentSpeaker}
                        disabled={!isSpeaking}
                        className="clear-current-speaker-button"
                    >
                        <span className="clear-current-speaker-button-text">Clear Current Speaker</span>
                    </Button>
                </div>
            )}
            <CalledSpeaker />
            <div className="speaker-list">
                <ul>
                    {getSpeakersForSelectedItem().map((speaker, index) => (
                        calledSpeakerUid !== speaker.uid && (
                            <li key={index} onClick={() => setSelectedIndex(index)} className={selectedIndex === index ? 'speakerselected' : ''}>
                                <div className="speaker-list-name">
                                    {speaker.name}
                                </div>
                                {selectedIndex === index && (
                                    <>
                                        {isChair && (
                                            <div className="speaker-actions">
                                                <button
                                                    type="button"
                                                    className="call-speaker-button"
                                                    title="Call Speaker"
                                                    id="call speaker"
                                                    onClick={() => callSpeaker(speaker)}
                                                >
                                                    <i className="fa fa-podium" aria-hidden="true" />
                                                </button>
                                                <button
                                                    className="delete-speaker-button"
                                                    title="Delete Speaker"
                                                    id="delete speaker"
                                                    onClick={() => setIsDeleteConfirmationOpen(true)}
                                                >
                                                    <i className="fa fa-trash" aria-hidden="true" />
                                                </button>

                                                {isDeleteConfirmationOpen && (
                                                    <DeleteConfirmation
                                                        onClose={() => setIsDeleteConfirmationOpen(false)}
                                                        onDelete={() => attendeeDeleteSpeaker(speaker)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </li>
                        )
                    ))}
                </ul>
            </div>
        </div>
    );
}