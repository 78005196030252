import { SpeakerType } from '../../constants/cloudVotingConstants';

export function speakerCustomSort(speakerList) {
    if (!Array.isArray(speakerList) || speakerList.length === 0) {
        return []; // Return an empty array if the input is not an array or is empty
    }

    // Sort the speaker list by created_at if available, otherwise by position
    const sortedByCreatedAtOrPosition = [...speakerList].sort((a, b) => {
        // Determine the speaker type for both speakers
        const speakerTypeA = a.title ?? a.speaker_type;
        const speakerTypeB = b.title ?? b.speaker_type;

        // Check if each speaker is an elected official
        const isElectedOfficialA = speakerTypeA === SpeakerType.ELECTED_OFFICIAL;
        const isElectedOfficialB = speakerTypeB === SpeakerType.ELECTED_OFFICIAL;

        if (isElectedOfficialA && isElectedOfficialB) {
            // Both are ELECTED_OFFICIAL, sort by created_at
            const dateA = a.created_at ? new Date(a.created_at).getTime() : 0;
            const dateB = b.created_at ? new Date(b.created_at).getTime() : 0;
            return dateA - dateB;
        }

        if (!isElectedOfficialA && !isElectedOfficialB) {
            // Neither is ELECTED_OFFICIAL, sort by position
            return (a.position ?? Number.MAX_VALUE) - (b.position ?? Number.MAX_VALUE);
        }

        // If one is ELECTED_OFFICIAL and the other isn't, prioritize ELECTED_OFFICIAL
        return isElectedOfficialA ? -1 : 1;
    });

    const sortedSpeakerList = [];
    //By default, all elected officials will appear at the top. However, if a public user or staff member manually changes the position of any official, 
    //the "specialPosition" comes into play and is used for sorting.

    // Handle entries with special positions (if they exist)
    const withSpecialPosition = sortedByCreatedAtOrPosition.filter(speaker => speaker.specialPosition && speaker.specialPosition > 0);
    const withoutSpecialPosition = sortedByCreatedAtOrPosition.filter(speaker => !speaker.specialPosition || speaker.specialPosition === 0);

    // Place entries with special positions in the exact spot, shifting if necessary
    withSpecialPosition.forEach(speaker => {
        let index = speaker.specialPosition - 1; // Convert 1-based to 0-based

        // If the spot is occupied, shift the existing elements
        while (sortedSpeakerList[index] !== undefined) {
            index++; // Move to the next available position
        }

        sortedSpeakerList[index] = speaker; // Place the speaker in the correct position
    });

    // Sort remaining entries by title or speaker_type and created_at or position
    withoutSpecialPosition.sort((a, b) => {
        // Public and Staff are considered in the same order.
        // If Public and Staff do not have special positions after elected officials, they should be sorted based on their respective position.
        const titleOrder = { [SpeakerType.ELECTED_OFFICIAL]: 0, [SpeakerType.PUBLIC]: 1, [SpeakerType.STAFF]: 1 };

        const speakerTypeA = a.title ?? a.speaker_type;
        const speakerTypeB = b.title ?? b.speaker_type;

        // Check if the speaker types are different
        if (titleOrder[speakerTypeA] !== titleOrder[speakerTypeB]) {
            return titleOrder[speakerTypeA] - titleOrder[speakerTypeB]; // Sort by title or speaker_type (ELECTED_OFFICIAL first, then PUBLIC/STAFF)
        }

        // Check if each speaker is an elected official
        const isElectedOfficialA = speakerTypeA === SpeakerType.ELECTED_OFFICIAL;
        const isElectedOfficialB = speakerTypeB === SpeakerType.ELECTED_OFFICIAL;

        if (isElectedOfficialA && isElectedOfficialB) {
            // Both are ELECTED_OFFICIAL, sort by created_at
            const dateA = a.created_at ? new Date(a.created_at).getTime() : 0;
            const dateB = b.created_at ? new Date(b.created_at).getTime() : 0;
            return dateA - dateB;
        }

        if (!isElectedOfficialA && !isElectedOfficialB) {
            // Neither is ELECTED_OFFICIAL, sort by position
            return (a.position ?? Number.MAX_VALUE) - (b.position ?? Number.MAX_VALUE);
        }

        // If one is ELECTED_OFFICIAL and the other isn't, prioritize ELECTED_OFFICIAL
        return isElectedOfficialA ? -1 : 1;
    });

    // Place remaining entries in the next available position
    withoutSpecialPosition.forEach(speaker => {
        let index = 0;

        // Find the next available index in the final sortedSpeakerList
        while (sortedSpeakerList[index] !== undefined) {
            index++;
        }

        sortedSpeakerList[index] = speaker; // Place the speaker in the next available position
    });

    return sortedSpeakerList;
}