import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calledSpeaker: {
    agenda_uid: '',
    customer_uid: '',
    duration: '',
    item_uid: '',
    name: '',
    position: '',
    speaker_type: '',
    start_time: '',
    status: '',
    uid: ''
  },
  speakers: [],
  activeTab: '1'
};

const liveMeetingSpeaker = createSlice({
  name: 'speaker',
  initialState,
  reducers: {
    setSpeakers: (state, { payload }) => {
      state.speakers = payload;
    },
    setCalledSpeaker: (state, { payload }) => {
      state.calledSpeaker = payload;
    },
    deleteSpeaker: (state, { payload }) => {
      // Find the index of the speaker to be deleted
      const index = state.speakers.findIndex(sp => sp.uid === payload);

      // If the speaker exists in the array
      if (index !== -1) {
        // Remove the speaker from the array using filter for immutability
        state.speakers = state.speakers.filter(sp => sp.uid !== payload);
      }

      // If the called speaker is the one being deleted, clear the called speaker
      if (state.calledSpeaker?.uid === payload) {
        state.calledSpeaker = undefined;
      }
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    upsertSpeaker: (state, { payload }) => {
      const index = state.speakers.findIndex(sp => sp.uid === payload.uid);
      if (index !== -1) {
        state.speakers[index] = payload;
      }
      else {
        state.speakers.push(payload);
      }
    },
    updateSpeaker: (state, { payload }) => {
      const index = state.speakers.findIndex(sp => sp.uid === payload.uid);
      if (index !== -1) {
        state.speakers[index] = payload;
      }
    },
  }
});

export default liveMeetingSpeaker.reducer;

export const {
  setSpeakers,
  setCalledSpeaker,
  deleteSpeaker,
  setActiveTab,
  upsertSpeaker,
  updateSpeaker
} = liveMeetingSpeaker.actions;


