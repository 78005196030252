let settings = {
  paginationDateFormat: 'MMM YYYY',
  monthDisplayFormat: 'M/D/YYYY',
  timeDisplayFormat: 'h:mm A',
  monthAndTimeDisplayFormat: 'M/D/YYYY, h:mm A',
  memaStartTimeUrlParamFormat: 'YYYY-MM-DD',
  memaEndTimeUrlParamFormat: 'YYYY-MM-DD HH:mm:ss',
  metaDownloadUrl: '/api/metadata/download_attachment/',
  metaViewUrl: '/api/metadata/view_attachment/',
  annotationUrl: '/api/legislate/annotations/',
  vcdmonthAndTimeDisplayFormat: 'M/D/YYYY, h:mm A',

  getPSPDFKitToken() {
    if (window.gon) {
      // eslint-disable-next-line no-undef
      return gon.pspdfkit_token
    }
  },

  getBaseUrl() {
    return `${window.location.protocol}//${window.location.host}/legislate/`;
  },
};

export default settings;
