/**
 * Display a count down timer by second, use start_time and duration
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { secondsToTimeString } from '../../../../utils/dateTimeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'antd';
import { selectSelectedItemKey } from '../../ActiveAgenda/activeAgendaSlice';
import { SpeakerStatus, SpeakerType } from '../../../../constants/cloudVotingConstants';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const CalledSpeaker = () => {

  const calledSpeaker = useSelector((state) => state.liveMeetingSpeaker.calledSpeaker);
  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemsDictionary = useSelector(state => state.liveMeeting.agendaItemsDictionary);
  const selectedItemHypatia = itemsDictionary[selectedItemKey];
  const start_time = dayjs.utc(calledSpeaker?.start_time);
  const duration = calledSpeaker?.duration;

  let calledSpeakerRemainingTime = 0;
  // this logic will retain the remaining in the timer to fix the issue where 
  // whole duration was shown in timer for a second while coming back speaker tab
  if (calledSpeaker) {
    calledSpeakerRemainingTime = Math.floor((dayjs().utc() - start_time) / 1000);
    if (calledSpeaker.status === SpeakerStatus.STOPPED) {
      calledSpeakerRemainingTime = (duration ?? 0) > 0 ? duration : 0;
    } else if (calledSpeaker.status === SpeakerStatus.STARTED) {
      calledSpeakerRemainingTime = ((duration ?? 0) > 0 ? duration : 0) - calledSpeakerRemainingTime;
    } else {
      calledSpeakerRemainingTime = (duration ?? 0) > 0 ? duration : 0;
    }
  }

  const [remainingTime, setRemainingTime] = useState(() => {
    const initialTime = parseInt(calledSpeakerRemainingTime); // Ensure it's a valid number
    return !isNaN(initialTime) && initialTime > 0 ? initialTime : 0; // Use the value as is, including negatives
  });

  useEffect(() => {
    const newTime = parseInt(calledSpeakerRemainingTime);
    if (!isNaN(newTime)) {
      setRemainingTime(newTime > 0 ? newTime : 0);
    }
  }, [calledSpeakerRemainingTime]);

  const isSpeaking =
    !!calledSpeaker?.name && selectedItemHypatia?.uid === calledSpeaker?.item_uid;

  useEffect(() => {
    if (calledSpeaker && calledSpeaker.status === SpeakerStatus.STARTED) {
      const interval = setInterval(() => {
        const elapsedTime = Math.floor((dayjs().utc() - start_time) / 1000);
        const timeLeft = duration - elapsedTime;
        setRemainingTime(timeLeft > 0 ? timeLeft : 0);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [calledSpeaker, start_time, duration]);

  return (
    <>
      <div className="speaker-called display-flex">
        <div className="flex-1">
          <div className="speaker-called-name">
            <FontAwesomeIcon icon="user" size="lg" />
            <div>
              <div className="sub-title">
                {isSpeaking ? calledSpeaker?.name : 'No one is speaking'}
              </div>
              <div className="sub-text">Current Speaker</div>
            </div>
          </div>
        </div>

        <div className="flex-auto">
          <div className="sub-title">
            {secondsToTimeString(
              isSpeaking
                ? [SpeakerStatus.CALLED, SpeakerStatus.STOPPED].includes(calledSpeaker?.status)
                  ? duration
                  : remainingTime
                : 0
            )}
          </div>
          <div className="sub-text">Time Remaining</div>
        </div>
      </div>
      {isSpeaking && (
        <Progress
          percent={calledSpeaker?.status === SpeakerStatus.STARTED ? ((duration - remainingTime) / duration) * 100 : 0}
          showInfo={false}
          strokeColor="#99bfdb"
          trailColor={duration === 0 ? "#99bfdb" : "#005EA2"}
          className={`progress-bar ${calledSpeaker?.status === SpeakerStatus.CALLED && calledSpeaker?.speaker_type === SpeakerType.ELECTED_OFFICIAL && (duration === -1 || duration === 0) ? 'pulsing' : ''}`}
        />
      )}
    </>
  );
};

export default CalledSpeaker;
