import { createSlice } from "@reduxjs/toolkit";

export const SettingKeys = {
  CloudVotingEnableForClient: "cloudvoting_enable"
};

const initialState = {
  CloudVotingEnableForClient: false,
  CloudVotingEnableForEvent: false,
  ShowIndividualVoteForEvent: false
};

const settingsState = createSlice({
  name: "settingsState",
  initialState,
  reducers: {
    setSettingValue: (state, { payload }) => {
      for (var key in payload) {
        switch (key) {
          case SettingKeys.CloudVotingEnableForClient:
            state.CloudVotingEnableForClient = payload[key] === "1";
            break;

          default:
            break;
        }
      }
    },
    setSettingValueForEvent: (state, { payload }) => {
        state.CloudVotingEnableForEvent = payload.CloudVotingEnableForEvent;
        state.ShowIndividualVoteForEvent = payload.ShowIndividualVoteForEvent;
    },
  },
});

export const { setSettingValue, setSettingValueForEvent } = settingsState.actions;

export default settingsState.reducer;

export const selectSettings = (state) => state.settingsState;
export const selectIsCloudVotingEnabled = (state) =>
  state.settingsState.CloudVotingEnableForClient && state.settingsState.CloudVotingEnableForEvent;
export const selectIsShowIndividualVote = (state) =>
  state.settingsState.CloudVotingEnableForClient && state.settingsState.ShowIndividualVoteForEvent;
